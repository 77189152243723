import { useCallback, useEffect, useState } from "react";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import useAuth from "../../hooks/useAuth";
import HttpService from "../../services/http";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import PlansModalBody from "../../components/PlansModalBody";
import { Timer } from "./ForgotPassword";
import BsInputPassword from "../../components/public/BsInputPassword";
import { AxiosError } from "axios";
import { Helmet } from "react-helmet-async";
import useAppLocalStorage from "../../hooks/useAppLocalStorage";

const schema = Joi.object({
  user_email: Joi.string().required().label("Email"),
  user_password: Joi.string().required().label("Password"),
  user_remember_me: Joi.boolean().default(false),
}).required();

type LoginType = {
  user_email: string;
  user_password: string;
  user_remember_me: boolean;
};

const Login = () => {
  const localStorage = useAppLocalStorage();
  const auth = useAuth();

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  let location: any = useLocation();
  const [searchParams] = useSearchParams();

  let from = location.state?.from?.pathname || "/control-panel";

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<LoginType>({
    resolver: joiResolver(schema),
  });

  const [showTimer, setShowTimer] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showPlansModal, setShowPlansModal] = useState(false);
  const [userId, setUserId] = useState<string>();
  const [isEmailVarificationError, setIsEmailVarificationError] =
    useState(false);

  const LoginUserMutation = useMutation<any, AxiosError<any, any>>(
    (credentials: any) => HttpService.post("/auth/login", credentials),
    {
      onMutate: () => {
        setShowAlert(false);
        setIsEmailVarificationError(false);
      },
      onError: (err) => {
        if (err.response?.status === 503) {
          toast.error(
            "Service Temporarily Unavailable. Please try again later."
          );
        } else {
          toast.error(err.response?.data.message || err.message);
        }

        if (
          err.response?.status === 400 &&
          err.response?.data.code === "RSUSKO014"
        ) {
          setUserId(err.response?.data.element.userIdentification);
          setIsEmailVarificationError(true);
        } else {
          localStorage.removeItem("_cdn_credential");
        }

        setShowAlert(true);
      },
    }
  );

  const ResendEmailVerification = useMutation<
    any,
    AxiosError<any, any>,
    string
  >(
    (user_identification) =>
      HttpService.post(`/auth/${user_identification}/validation-link`),
    {
      onSuccess: () => {
        toast.success(
          "The verification link has been sent to your email address."
        );
        setShowTimer(true);
      },
      onError: (err) => {
        toast.error(err.response?.data.message || err.message);
        setShowTimer(false);
      },
    }
  );

  const autoLoginUser = useCallback(
    (parsedCredential: any, user_selected_plan: string) => {
      LoginUserMutation.mutate(parsedCredential, {
        onSuccess: (res) => {
          queryClient
            .fetchQuery("payment-status", () =>
              HttpService.get(
                `/auth/${res.data.element.userIdentification}/verify-payment`
              )
            )
            .then((value) => {
              localStorage.removeItem("_cdn_credential");
              localStorage.removeItem("_cdn_service_client_plan");

              auth.login({
                ...res.data.element,
                user_email: parsedCredential.user_email,
                accessToken: value.data.element.accessToken,
                rememberMe: watch("user_remember_me"),
              });

              return navigate(
                `/control-panel?user_plan=${user_selected_plan}`,
                { replace: true }
              );
            })
            .catch((err: any) => {
              localStorage.removeItem("_cdn_credential");

              if (err.response?.status === 402) {
                setShowPlansModal(true);
                setUserId(res.data.element.userIdentification);
              } else {
                toast.error(err.response?.data.message || err.message);
              }
            });
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth, from, navigate, watch]
  );

  useEffect(() => {
    let cleaner = true;

    if (cleaner && auth.user) navigate(from);

    let credentials = localStorage.getItem("_cdn_credential");
    let user_selected_plan = localStorage.getItem("_cdn_service_client_plan");

    if (
      cleaner &&
      credentials &&
      user_selected_plan &&
      searchParams.has("status") &&
      searchParams.get("status") === "true"
    ) {
      let { is_new_user, user_identification, ...rest } = JSON.parse(
        credentials as string
      );

      if (is_new_user) autoLoginUser(rest, user_selected_plan);
    } else reset();

    return () => {
      cleaner = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, from, navigate, reset, autoLoginUser]);

  const handleResendValidationEmail = () => {
    if (userId) ResendEmailVerification.mutate(userId);
  };

  const onSubmit = (data: any) => {
    let { user_remember_me, ...rest } = data;

    LoginUserMutation.mutate(rest, {
      onSuccess: (res) => {
        queryClient
          .fetchQuery("payment-status", () =>
            HttpService.get(
              `/auth/${res.data.element.userIdentification}/verify-payment`
            )
          )
          .then((value) => {
            if (value.data.element.plans.length > 0) {
              auth.login({
                ...res.data.element,
                user_email: data.user_email,
                accessToken: value.data.element.accessToken,
                rememberMe: user_remember_me,
              });

              if (
                localStorage.getItem("_cdn_service_client_plan") &&
                searchParams.has("status") &&
                searchParams.get("status") === "true"
              ) {
                let user_selected_plan = localStorage.getItem(
                  "_cdn_service_client_plan"
                );

                localStorage.removeItem("_cdn_service_client_plan");

                navigate(`/control-panel?plan_name=${user_selected_plan}`, {
                  replace: true,
                });
              } else navigate(from, { replace: true });
            } else {
              localStorage.setItem(
                "_cdn_credential",
                JSON.stringify({
                  user_identification: res.data.element.userIdentification,
                  user_email: data.user_email,
                  user_password: data.user_password,
                  is_new_user: true,
                })
              );

              setShowPlansModal(true);
              setUserId(res.data.element.userIdentification);
            }
          })
          .catch((err: any) => {
            if (
              err.response?.status === 402 &&
              err.response?.data.code === "RSPYKO001"
            ) {
              setShowPlansModal(true);
              setUserId(res.data.element.userIdentification);
            } else toast.error(err.response?.data.message || err.message);
          });
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>1CDN - Login</title>
        <meta name="description" content="1CDN - Login" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE}/login`}
        />
      </Helmet>

      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="fs-4 fw-bold mb-0">Login</h1>
        <small>
          Don’t have an account?{" "}
          <Link to="/register" className="text-black">
            Create now
          </Link>
        </small>
      </div>

      {searchParams.has("payment-status") &&
        searchParams.get("payment-status") === "failure" && (
          <Alert variant="danger">
            Your subscription is ended or you don't have an active subscription.
          </Alert>
        )}

      {searchParams.has("status") && searchParams.get("status") === "true" && (
        <Alert variant="success">
          Congratulations! You now have a plan. Log in to continue.
        </Alert>
      )}

      {isEmailVarificationError && showAlert && (
        <Alert
          variant="warning"
          onClose={() => setShowAlert(false)}
          dismissible
        >
          <div className="d-flex flex-wrap align-items-center">
            Didn't receive your confirmation email?
            {showTimer ? (
              <span className="text-muted ms-2">
                <Timer setShowTimer={setShowTimer} ttl={120} /> left
              </span>
            ) : (
              <Button
                variant="link"
                className="p-0 border-0 ms-2"
                style={{ verticalAlign: "top", outline: "none" }}
                onClick={handleResendValidationEmail}
                disabled={showTimer}
              >
                {ResendEmailVerification.isLoading ? (
                  <Spinner animation="grow" size="sm" />
                ) : (
                  "Resend email"
                )}
              </Button>
            )}
          </div>
        </Alert>
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="user_email">Email</Form.Label>
          <Form.Control
            id="user_email"
            type="email"
            isInvalid={
              !!errors.user_email ||
              LoginUserMutation.error?.response?.data.user_email
            }
            {...register("user_email")}
          />
          <Form.Control.Feedback type="invalid">
            {errors.user_email?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <BsInputPassword
          label="Password"
          isInvalid={!!errors.user_password}
          errorMessage={errors.user_password?.message}
          className={"mb-3"}
          rhfControls={register("user_password")}
        />

        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            id="remember-me"
            label="Remember me"
            {...register("user_remember_me")}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Link to="/forgot-password" className="text-black fw-bold">
            Forgot password?
          </Link>
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          className="w-100 rounded-pill auth-btn"
          size="lg"
          disabled={
            LoginUserMutation.isLoading ||
            !!queryClient.isFetching("payment-status")
          }
        >
          {LoginUserMutation.isLoading ||
          queryClient.isFetching("payment-status") ? (
            <Spinner animation="grow" />
          ) : (
            "Log in"
          )}
        </Button>
      </Form>

      <Modal show={showPlansModal} backdrop="static" keyboard={false} size="xl">
        <Modal.Header>
          <Modal.Title>Choose your plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PlansModalBody user_identification={userId as string} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Login;
